<template>
    <div>
        <div class="header">
             <span @click="gouot"></span>
             <span>AGV叉车任务列表</span>
             <span @click="islogout">退出</span>
        </div>
        <div class="warper">
            <van-tabs class="con-tabs" v-model="active" @change="tabHandler">
                <van-tab title="全部任务">
                    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                    <van-list
                        v-model="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="onLoad"
                        :offset="200"
                    >
                    <div class="content-infp" v-for="item in list" :key="item">
                        <p>发起时间：{{item.create_time}}</p>
                        <p>任务编号：{{item.task_id}}</p>
                        <p>任务线路：{{item.ModeName}}</p>
                        <p>起点库位：{{item.source}}</p>
                        <p>终点库位：{{item.destination}}</p>
                        <p>物料种类：{{item.material_type}}</p>
                        <p>当前状态：{{item.StatusText}}</p>
                       
                    </div>
                    </van-list>
                    </van-pull-refresh>
                </van-tab>
                <van-tab title="我下达的任务">
                    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                    <van-list
                        v-model="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="onLoad"
                    >
                    <div class="content-infp" v-for="item in list" :key="item">
                        <p>发起时间：{{item.create_time}}</p>
                        <p>任务编号：{{item.task_id}}</p>
                        <p>任务线路：{{item.ModeName}}</p>
                        <p>起点库位：{{item.source}}</p>
                        <p>终点库位：{{item.destination}}</p>
                        <p>物料种类：{{item.material_type}}</p>
                        <p>当前状态：{{item.StatusText}}</p>
                         <van-button v-if="item.status == 1" @click="quxiao(item)" type="info">取消指令</van-button>
                    </div>
                    </van-list>
                    </van-pull-refresh>
                </van-tab>
            </van-tabs>
            <van-button class="btn" round size="large" type="info" @click="addInstruct">新建指令</van-button>
        </div>
         <van-popup
            v-model="isAddShow"
            :closeable="false"
            :close-on-click-overlay="false"
            position="bottom"
            :style="{
                height: '100%',
            }"
        >
           <Instruct @closePage="closePage"  ref="structRef" />
        </van-popup>
    </div>
</template>

<script>
import { Toast } from 'vant';
import Instruct from './components/Instruct.vue'
    export default {
        components: {
            Instruct,
        },
        data() {
            return {
                active: 0,
                count:0,
                refreshing: false,//上啦的
                loading: false,//下拉等待圈圈
                finished: false,//下拉的状态 
                isAddShow: false,//新建指令
                list:[],
                total:'',
                page:0,
                pageSize:10, 
                isType:'',
            }
        },
        mounted () {
            // this.lodData(this.pageSize,this.page);
        },
        methods: {
            quxiao(i){
                  var toast = Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: "加载中..."
                });
                this.$http.post('index.php/agv/CancelOrder',{id:i.id}).then(res=>{
                     toast.clear()//清除加载效果
                    if(res.data.code == 0){
                        this.onRefresh()
                        this.$toast.success(res.data.msg)
                    }else if(res.data.code == -1){
                        this.islogout()
                    }else{
                         toast.clear()//清除加载效果
                        this.$toast.fail(res.data.msg)
                    }
                }).catch(()=>{
                     toast.clear()//清除加载效果
                })
            },
            tabHandler(name,title){
                if(name == 1){
                    this.isType = 1
                }else{
                     this.isType = undefined
                }
                 this.list = []
                this.page = 0
                this.onLoad()
            },
            //打开弹窗
            addInstruct(){
                this.isAddShow = true
                this.$nextTick(()=>{
                    this.$refs.structRef.kong()
                })
            },
            //关闭弹窗
            closePage(){
                this.isAddShow = false
                this.page = 0
                 this.list = []
                this.onLoad()
            },
           //返回
            gouot() {
                this.$router.push('/AGV')
            },
            //加载 上拉
            onRefresh() {
                this.page = 0
                        // 清空列表数据
                this.list = []
                this.finished = false;
                this.refreshing = false
                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.onLoad();
            },
            //下拉
            onLoad(){
                let this_ = this
                let toast = Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: "加载中..."
                });
                setTimeout(() => {
                    let page = this_.page + 1
                    this_.page = page
                    let pageSize = this_.pageSize
                    let obj={
                        pageSize,page,
                        type:this.isType
                    }
                    
                    this_.$http.post('index.php/agv/TaskList',obj).then(res=>{
                        toast.clear();
                     
                        if(res.data.code == 0){

                            if(res.data.data.data.length == 0){
                                //this_.list = []
                                this_.finished = true;
                                this_.refreshing = false
                            }else{
                                let arr = res.data.data.data
                                arr.forEach(element => {
                                    this_.list.push(element)
                                });
                                if(this_.list.length >= res.data.data.total){
                                    this_.finished = true;
                                    this_.refreshing = false
                                }else{
                                    this_.finished = false;
                                    // this_.refreshing = false
                                }
                            }
                            this_.loading = false;
                        }else if(res.data.code == -1){
                            this.islogout()
                        }else{
                            this_.finished = true;
                            this_.refreshing = false
                            toast.clear();
                            this.$toast.fail(res.data.msg)
                        }
                    }).catch(()=>{
                        this_.finished = true;
                        this_.refreshing = false
                        toast.clear();
                        this.$toast.fail('请求出错！')
                    })
                   
                }, 1000);

            },
        },
    }
</script>

<style lang="scss" scoped>
.header {
    position: fixed;
    top: 0;
    z-index: 9;
    width: calc(100% - 40px);
    padding: 0 20px;
    background-color: cadetblue;
    height: 1rem;
    line-height: 1rem;
    color: white;
    font-size: 0.4rem;
    display: flex;
    justify-content: space-between;
    span {
        display: inline-block;
    }
    span:nth-child(1) {
        width: 30px;
        height: 30px;
        background-image: url(../../assets/u39.png);
        background-size: 100%;
        margin-top: 8px;
    }
}
.warper{
    padding: 10px;
    padding-top: 50px;
    .con-tabs{
        height: calc(100vh - 3rem);
        overflow: scroll;
        .content-infp{
            margin-top: 10px;
            padding: 20px;
            border-radius: 8px;
            border: 1px solid #eee;
            overflow: hidden;
            .van-button{
                float: right;
            }
            p{
                line-height: 30px;
                margin: 0;
            }
        }
        .van-list{
            height: 80vh;
            overflow: scroll;
        }
    }
    .btn{
        margin-top: 20px;
    }
}

</style>