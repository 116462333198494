<template>
    <div>
        <div class="header">
             <span ></span>
             <span>AGV叉车调度指令</span>
             <span @click="closePage"><van-icon name="close" /></span>
        </div>
        <div class="content">
            <van-field
                readonly
                clickable
                label-width="120"
                name="picker"
                v-model="ChanneNmae"
                label="选择调度路线："
                placeholder="选择调度路线"
                @click="isShow = true"
                >
              
            </van-field>
            <van-popup v-model="isShow" position="bottom"  :close-on-click-overlay="false">
                <van-picker
                    show-toolbar
                    :columns="ChanneList"
                    value-key="name"
                    @confirm="onConfirm"
                    @cancel="isShow = false"
                />
            </van-popup>
            <van-field
                readonly
                clickable
                label-width="120"
                name="picker"
                v-model="startZi"
                label="设置起点库位："
                placeholder="设置起点库位"
                @click="isstart = true"
                >
              
            </van-field>
            <van-popup v-model="isstart" position="bottom"  :close-on-click-overlay="false">
                <van-picker show-toolbar @cancel="isstart=false" value-key="name" @confirm="startHandler" :columns="columns" @change="onChange" />
            </van-popup>
            <van-field
                readonly
                clickable
                label-width="120"
                name="picker"
                v-model="endZi"
                label="设置终点库位："
                placeholder="设置起点库位"
                @click="isend = true"
                >
              
            </van-field>
            <van-popup v-model="isend" position="bottom"  :close-on-click-overlay="false">
               <van-picker show-toolbar @cancel="isend=false" value-key="name" :columns="endColumns"  @confirm="startHandler1" @change="onChangeStart" />
            </van-popup>
            <van-field
                label-width="120"
                name="picker"
                v-model="MaterialType"
                label="物料种类："
                placeholder="请输入物料种类"
                ></van-field>
        </div>
         <div class="bottom-btn">
            <van-button class="btn"   size="large" type="info" @click="giveOrder">立刻下达指令</van-button>
           
        </div>
    </div>
</template>

<script>
import { Toast,Dialog } from 'vant'
    export default {
        data() {
            return {
                isShow:false,
                ChanneNmae:"",
                ChanneList:[
                    {name:'-----请选择调度路线-----',id:""},
                    {name:'【1】预处理区到暂存区',id:"1"},
                    {name:'【2】暂存区到翻转机区',id:"2"},
                    {name:'【3】翻转机区到破拆壳体区',id:"3"},
                    {name:'【4】翻转机周转箱区到零配件区',id:"4"},
                    {name:'【5】零配件区返回翻转机周转箱区',id:"5"},
                ],
                endList:[],//终点
                startList:[],//起点
                isend:false,//关闭
                isstart:false,//开始
                columns:[],//开始的数组
                endColumns:[],//关闭的数组
                startZi:'',//开始的字
                startValue:'',//开始的Id
                endZi:'',//关闭的现在
                endValue:'',//关闭的ID
                MaterialType:"",
                Mode:'',//选择的类型
            }
        },
        mounted () {
            ;
        },
        methods: {
            kong(){
                this.ChanneNmae = ''
                this.startZi = ''
                this.endZi = ''
                this.MaterialType = ''
                this.endList = []
                this.startList = []
                this.columns = []
                this.endColumns = []
            },
            giveOrder(){
                let confirmMsg='请确认';
                let confirmMsg_s='';
                let confirmMsg_l='';
                let confirmMsg_e='';
                if(this.startZi!=""){
                    confirmMsg_s=`起点库位【${this.startZi}】已放置物料`;
                }
                if(confirmMsg_s!=''){
                    confirmMsg_l='，'
                }
                if(this.startZi!=""){
                    confirmMsg_e=`终点库位【${this.endZi}】已清空场地`;
                }
                
                 Dialog.confirm({
                    title: '提示',
                    message: confirmMsg+confirmMsg_s+confirmMsg_l+confirmMsg_e+'后，点击确定下达指令',
                })
                .then(() => {
                    // on confirm
                   this.getData()
                })
                .catch(() => {
                    // on cancel
                });
               
            },
            getData(){
                 let obj = {
                    Mode:this.Mode,
                    Source:this.startValue,
                    Destination:this.endValue,
                    MaterialType:this.MaterialType,
                }
                 var toast = Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: "加载中..."
                });
                this.$http.post('index.php/agv/CreateTask',obj).then(res=>{
                    toast.clear()//清除加载效果
                    if(res.data.code == 0){
                        console.log(res)
                        this.closePage()
                        this.$toast.fail(res.data.msg)
                    }else if(res.data.code == -1){
                        this.islogout()
                    }else{
                        toast.clear()//清除加载效果
                         this.$toast.fail(res.data.msg)
                    }
                })
                .catch(()=>{
                    toast.clear()//清除加载效果
                })
            },
            onChange(picker, values){
                picker.setColumnValues(1, this.startList[values[0]]);
            },
            onChangeStart(picker, values){
                picker.setColumnValues(1, this.endList[values[0]]);
            },
            //确定
            startHandler(e){
                this.startZi = e[0] + '-' + e[1].name
                this.startValue =  e[1].id
                this.endZi = ''
                this.endValue = ''
                // this.endList = []
                this.isstart = false
            },
            //确定
            startHandler1(e){
                this.endZi = e[0] + '-' + e[1].name
                this.endValue =  e[1].id
                this.isend = false
            },
            //关闭
            closePage() {
                this.$emit('closePage')
            },
            onConfirm(e){
               this.columns = []
                this.endColumns=[]
                 this.startZi = ''
                  this.startValue=''
                 this.endZi = ''
                  this.endValue=''
                this.Mode = e.id
                this.ChanneNmae =e.name
                this.isShow =false
                this.$http.post('/index.php/agv/selectPattern',{type:e.id}).then(res=>{
                     if(res.data.code == 0){
                        this.endList = res.data.data.end
                        this.startList = res.data.data.start
                         console.log(this.startList)
                        if(this.startList.length!=0){
                            // this.cities = arr
                            this.columns.push({values:Object.keys(this.startList)},{ values:this.startList[Object.keys(this.startList)[0]]})
                        }
                        if(this.endList.length !=0){
                            this.endColumns.push({values:Object.keys(this.endList)},{ values:this.endList[Object.keys(this.endList)[0]]})
                        }
                    }else if(res.data.code == -1){
                        this.islogout()
                    }else{
                         this.$toast.fail(res.data.msg)
                    }
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
.header {
    position: fixed;
    top: 0;
    z-index: 9;
    width: calc(100% - 40px);
    padding: 0 20px;
    background-color: cadetblue;
    height: 1rem;
    line-height: 1rem;
    color: white;
    font-size: 0.4rem;
    display: flex;
    justify-content: space-between;
    span {
        display: inline-block;
    }
  
}
.content{
    padding: 10px;
    margin-top: 10px;
    padding-top: 50px;
}
.bottom-btn{
    padding: 10px;
}
</style>